<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.c">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Podcast Award Category</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this redirect?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template>
						<v-col cols="12">
							<v-text-field v-model="category.name" :rules="validations.name" label="Category name"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>

				<ti-form-layout title="Questions">
					<template>

						<v-col cols="12" v-for="(question, index) in category.podcast_award_questions_attributes" v-if="!question['_destroy']" :key="question.question">
							<v-row>
								<v-col cols="auto">
									<v-btn icon color="error" @click="removeQuestion(question, index)">
										<v-icon>{{ $icons.delete }}</v-icon>
									</v-btn>
								</v-col>
								<v-col cols="12" lg="3">
									<v-select :items="question_types" v-model="question.question_type" :rules="validations.name" label="Type"></v-select>
								</v-col>
								<v-col cols="12" lg="5">
									<v-text-field v-model="question.question" :rules="validations.question" label="Question"></v-text-field>
								</v-col>
								<v-col cols="12" lg="3" v-if="question.question_type === 'select'">
									<v-combobox v-model="question.options" label="Options for dropdown" hint="hit tab to enter a new option, avoid using commas" multiple chips return-object></v-combobox>
								</v-col>

							</v-row>
						</v-col>
						<v-col cols="12">
							<v-row justify="end">
								<v-col cols="auto">
									<v-btn fab color="primary" @click="addQuestion">
										<v-icon>{{ $icons.plus }}</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import {call, sync} from "vuex-pathify";
import models from "../../models";

export default {
	name: "PodcastAwardCategoriesEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		category: sync('podcast_award_categories/podcast_award_category')
	},
	data: () => ({
		valid: false,
		validations: {
			name: validationRules('category', ['required', 'minLength:2']),
		},
		question_types: [
			{text: 'Text', value: 'text'},
			{text: 'Dropdown', value: 'select'},
			{text: 'Slider', value: 'slider'},
		]
	}),
	beforeMount() {
		if(this.id) {
			this.getCategory(this.id)
		}
	},
	methods: {
		getCategory: call('podcast_award_categories/getPodcastAwardCategory'),
		addQuestion(){
			this.category.podcast_award_questions_attributes.push(models.PodcastAwardQuestion())
		},
		removeQuestion(question, index){
			if(question.id){
				this.$set(question, '_destroy', true)
				// this.$set(this.category.podcast_award_questions_attributes[index], '_destroy', true)
			} else {
				this.category.podcast_award_questions_attributes.splice(index, 1)
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.podcast_award_categories.update(this.id, this.category)
						.then(response => {
							this.$toast.add('Podcast Award Category updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'PodcastAwardCategories'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.podcast_award_categories.create(this.category)
						.then(response => {
							this.$toast.add('Podcast Award Category updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'PodcastAwardCategories'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}
			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.podcast_award_categories.delete(this.id, this.track)
				.then(response => {
					this.$toast.add('Podcast Award Category deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'PodcastAwardCategories'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>